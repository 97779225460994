import { Component, ChangeDetectionStrategy } from '@angular/core';

import { PROMO_TRUST_LOGOS_FOR_DARK_BLUE_BG_LAPTOP } from '@bp/promo/shared/core';

@Component({
	selector: 'bp-full-footer',
	templateUrl: './full-footer.component.html',
	styleUrls: [ './full-footer.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullFooterComponent {

	protected readonly _trustLogosDefs = PROMO_TRUST_LOGOS_FOR_DARK_BLUE_BG_LAPTOP;

}
