<div #contentRef class="content-container">
	<ng-container *ngIf="hasHeader">
		<bp-svg-icon name="logo" />

		<h1 class="mat-headline uppercase text-center">{{ title }}</h1>
	</ng-container>

	<div class="before-content">
		<ng-content select=".before-content" />
	</div>

	<div class="content longread-content-typography">
		<ng-content />
	</div>
</div>

<bp-table-of-contents [contentRef]="contentRef" />
