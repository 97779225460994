import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SharedFeaturesAnalyticsModule } from '@bp/frontend/features/analytics';
import { SharedFeaturesSvgIconsModule } from '@bp/frontend/features/svg-icons';

import { SharedModule } from '@bp/promo/shared/core';

import {
	FeatureDescriptionComponent, FullFooterComponent, LandingWorkSmarterComponent, StatsComponent, StickyHeaderComponent,
	TestimonialsComponent
} from './components';

const EXPOSED = [
	FeatureDescriptionComponent,
	FullFooterComponent,
	LandingWorkSmarterComponent,
	StatsComponent,
	TestimonialsComponent,
	StickyHeaderComponent,
];

@NgModule({
	declarations: EXPOSED,
	exports: EXPOSED,
	imports: [
		CommonModule,
		MatButtonModule,
		ReactiveFormsModule,
		SharedFeaturesSvgIconsModule,
		SharedModule,
		SharedFeaturesAnalyticsModule,
		RouterModule,
	],
})
export class SharedLandingsModule {
}
