import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedFeaturesSvgIconsModule } from '@bp/frontend/features/svg-icons';

import { TableOfContentsComponent } from '@bp/promo/shared/components/table-of-contents';

@Component({
	selector: 'bp-default-page-shell',
	standalone: true,
	imports: [
		CommonModule,

		SharedFeaturesSvgIconsModule,
		TableOfContentsComponent,
	],
	templateUrl: './default-page-shell.component.html',
	styleUrls: [ './default-page-shell.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultPageShellComponent {

	@Input() title!: string;

	@Input() hasHeader = true;

}
