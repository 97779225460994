import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { RouterModule } from '@angular/router';

import { SharedDirectivesCoreModule } from '@bp/frontend/directives/core';

@Component({
	selector: 'bp-book-demo-button',
	standalone: true,
	imports: [
		MatLegacyButtonModule,
		SharedDirectivesCoreModule,
		RouterModule,
	],
	templateUrl: './book-demo-button.component.html',
	styleUrls: [ './book-demo-button.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookDemoButtonComponent {
}
