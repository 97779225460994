<div class="wrapper">
	<div class="main-block">
		<div class="trust-logos">
			<bp-picture
				*ngFor="let logo of _trustLogosDefs; let last = last"
				[src]="logo.src"
				[alt]="logo.alt"
				[svg]="logo.svg"
				[class.mr-3]="!last"
			/>
		</div>

		<div class="text">
			<bp-svg-icon name="logo" class="mb-4" />

			<h4 class="uppercase">Making Payments Easy</h4>

			<p data-nosnippet class="description text-center">
				BridgerPay is a global, cloud-based fintech platform designed to optimize your growing business payment
				needs.
			</p>

			<div data-nosnippet class="workflow-description footer-caption">
				BridgerPay is not a PSP, or an acquiring service, and we do not provide any processing merchant
				accounts. Bridger is a PaaS (payment-as-a-service) company that allows businesses to utilise one API to
				consume all payments from any method or provider that is connected within BridgerPay’s ecosystem.
			</div>
		</div>

		<div class="links">
			<a
				href="https://www.visa.com/splisting/viewSPDetail.do?spId=4690&coName=BRIDGER%20AI%20LIMITED&HeadCountryList=CYPRUS&reset=yes&pageInfo=1%3B30%3BASC%3BcoName"
				bpTargetBlank
				class="footer-caption"
			>
				<bp-picture src="/assets/landings/shared/footer/visa-global-registry" class="mr-2" />
				<span>Visa Global Registry of Service Providers</span>
			</a>
		</div>
	</div>

	<div class="copyright footer-caption">All Rights Reserved © BridgerPay</div>
</div>
