import type { Data } from '@angular/router';

import { DTO } from '@bp/shared/models/metadata';

import { RouteMetatags } from './route-metatags';

export type RouteDataMetatags = Data & {
	title?: string;
	// Can't be RouteMetatags class instance as metadata is created too late.
	metatags?: DTO<RouteMetatags>;
};

export function metatagsRouteDataOptions(config: DTO<RouteMetatags>): RouteDataMetatags {
	return {
		title: config.title,
		metatags: new RouteMetatags(config),
	};
}
