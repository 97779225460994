import { Component, ChangeDetectionStrategy, Input, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CurrentCrmLeadFacade } from '@bp/frontend/domains/crm/leads/+current-crm-lead-state';

@Component({
	selector: 'bp-landing-work-smarter',
	templateUrl: './landing-work-smarter.component.html',
	styleUrls: [ './landing-work-smarter.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingWorkSmarterComponent {

	private readonly __currentCrmLeadFacade = inject(CurrentCrmLeadFacade);

	@Input() titleFirstLine = 'Work Smarter.';

	constructor(
		private readonly _router: Router,
		private readonly _route: ActivatedRoute,
	) {
	}

	onEmailSubmit(email: string): void {
		void this.__currentCrmLeadFacade.updateAndSaveLeadKeptInStore({ email });

		void this._router.navigate([ '.', { email }], { fragment: 'form', relativeTo: this._route });
	}

}
